import * as React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { useAppSelector as useSelector } from 'Store/index';
import { equals, pick } from 'ramda';

import { Dashboard, documentRoutes } from '../../routes';
import Search from '../../routes/_Patient/SearchAndCreation';

import Layout from '../Layout/index';

import { getEnabledDocuments, getNavigableRoutes } from '../../utility/randomUtil';
import { getJWTData } from '../../utility/jwtAuthTools';
import PatientRecords from 'Routes/_PatientRecords';

type FocusChangeListener = () => void;
const compareKeys = ['patientid', 'visitid', 'useruuid']; // Which keys to use for detecting changed JWT

const createFocusListener = (): FocusChangeListener => {
  const oldValue = pick(compareKeys, getJWTData() ?? {});
  return (): void => {
    const newValue = pick(compareKeys, getJWTData() ?? {});
    if (!equals(oldValue, newValue)) location.replace('/');
  };
};

const MainRouter = (): JSX.Element => {
  const session = useSelector((s: { session: ISessionStore }) => s.session);
  const sections = getEnabledDocuments(session).map((d: { name: string }) => d.name);
  const navigableSections = getNavigableRoutes(session).map((d: { name: string }) => d.name);

  React.useEffect(() => {
    const listener = createFocusListener();
    window.addEventListener('focus', listener);
    return (): void => {
      window.removeEventListener('focus', listener);
    };
  });

  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="/" element={Dashboard(sections)} />
          <Route path="/search" element={<Search prevPatient={true} />} />
          <Route path="/patientRecords" element={<PatientRecords />} />
          {documentRoutes(navigableSections).map((route) => (
            <Route key={route.path} {...route} />
          ))}
          {/* If no route matches, return to dashboard */}
          <Route path="*" element={Dashboard(sections)} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default MainRouter;
